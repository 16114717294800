@font-face {
  font-family: 'entypo';
  src:  url('#{$fontsPath}/entypo.eot?q1pa5y');
  src:  url('#{$fontsPath}/entypo.eot?q1pa5y#iefix') format('embedded-opentype'),
    url('#{$fontsPath}/entypo.ttf?q1pa5y') format('truetype'),
    url('#{$fontsPath}/entypo.woff?q1pa5y') format('woff'),
    url('#{$fontsPath}/entypo.svg?q1pa5y#entypo') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="entypo-"], [class*=" entypo-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'entypo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.entypo-add-to-list:before {
  content: "\e900";
}
.entypo-classic-computer:before {
  content: "\e901";
}
.entypo-controller-fast-backward:before {
  content: "\e902";
}
.entypo-creative-commons-attribution:before {
  content: "\e903";
}
.entypo-creative-commons-noderivs:before {
  content: "\e904";
}
.entypo-creative-commons-noncommercial-eu:before {
  content: "\e905";
}
.entypo-creative-commons-noncommercial-us:before {
  content: "\e906";
}
.entypo-creative-commons-public-domain:before {
  content: "\e907";
}
.entypo-creative-commons-remix:before {
  content: "\e908";
}
.entypo-creative-commons-share:before {
  content: "\e909";
}
.entypo-creative-commons-sharealike:before {
  content: "\e90a";
}
.entypo-creative-commons:before {
  content: "\e90b";
}
.entypo-document-landscape:before {
  content: "\e90c";
}
.entypo-remove-user:before {
  content: "\e90d";
}
.entypo-warning:before {
  content: "\e90e";
}
.entypo-arrow-bold-down:before {
  content: "\e90f";
}
.entypo-arrow-bold-left:before {
  content: "\e910";
}
.entypo-arrow-bold-right:before {
  content: "\e911";
}
.entypo-arrow-bold-up:before {
  content: "\e912";
}
.entypo-arrow-down:before {
  content: "\e913";
}
.entypo-arrow-left:before {
  content: "\e914";
}
.entypo-arrow-long-down:before {
  content: "\e915";
}
.entypo-arrow-long-left:before {
  content: "\e916";
}
.entypo-arrow-long-right:before {
  content: "\e917";
}
.entypo-arrow-long-up:before {
  content: "\e918";
}
.entypo-arrow-right:before {
  content: "\e919";
}
.entypo-arrow-up:before {
  content: "\e91a";
}
.entypo-arrow-with-circle-down:before {
  content: "\e91b";
}
.entypo-arrow-with-circle-left:before {
  content: "\e91c";
}
.entypo-arrow-with-circle-right:before {
  content: "\e91d";
}
.entypo-arrow-with-circle-up:before {
  content: "\e91e";
}
.entypo-bookmark:before {
  content: "\e91f";
}
.entypo-bookmarks:before {
  content: "\e920";
}
.entypo-chevron-down:before {
  content: "\e921";
}
.entypo-chevron-left:before {
  content: "\e922";
}
.entypo-chevron-right:before {
  content: "\e923";
}
.entypo-chevron-small-down:before {
  content: "\e924";
}
.entypo-chevron-small-left:before {
  content: "\e925";
}
.entypo-chevron-small-right:before {
  content: "\e926";
}
.entypo-chevron-small-up:before {
  content: "\e927";
}
.entypo-chevron-thin-down:before {
  content: "\e928";
}
.entypo-chevron-thin-left:before {
  content: "\e929";
}
.entypo-chevron-thin-right:before {
  content: "\e92a";
}
.entypo-chevron-thin-up:before {
  content: "\e92b";
}
.entypo-chevron-up:before {
  content: "\e92c";
}
.entypo-chevron-with-circle-down:before {
  content: "\e92d";
}
.entypo-chevron-with-circle-left:before {
  content: "\e92e";
}
.entypo-chevron-with-circle-right:before {
  content: "\e92f";
}
.entypo-chevron-with-circle-up:before {
  content: "\e930";
}
.entypo-cloud:before {
  content: "\e931";
}
.entypo-controller-fast-forward:before {
  content: "\e932";
}
.entypo-controller-jump-to-start:before {
  content: "\e933";
}
.entypo-controller-next:before {
  content: "\e934";
}
.entypo-controller-paus:before {
  content: "\e935";
}
.entypo-controller-play:before {
  content: "\e936";
}
.entypo-controller-record:before {
  content: "\e937";
}
.entypo-controller-stop:before {
  content: "\e938";
}
.entypo-controller-volume:before {
  content: "\e939";
}
.entypo-dot-single:before {
  content: "\e93a";
}
.entypo-dots-three-horizontal:before {
  content: "\e93b";
}
.entypo-dots-three-vertical:before {
  content: "\e93c";
}
.entypo-dots-two-horizontal:before {
  content: "\e93d";
}
.entypo-dots-two-vertical:before {
  content: "\e93e";
}
.entypo-download:before {
  content: "\e93f";
}
.entypo-emoji-flirt:before {
  content: "\e940";
}
.entypo-flow-branch:before {
  content: "\e941";
}
.entypo-flow-cascade:before {
  content: "\e942";
}
.entypo-flow-line:before {
  content: "\e943";
}
.entypo-flow-parallel:before {
  content: "\e944";
}
.entypo-flow-tree:before {
  content: "\e945";
}
.entypo-install:before {
  content: "\e946";
}
.entypo-layers:before {
  content: "\e947";
}
.entypo-open-book:before {
  content: "\e948";
}
.entypo-resize-100:before {
  content: "\e949";
}
.entypo-resize-full-screen:before {
  content: "\e94a";
}
.entypo-save:before {
  content: "\e94b";
}
.entypo-select-arrows:before {
  content: "\e94c";
}
.entypo-sound-mute:before {
  content: "\e94d";
}
.entypo-sound:before {
  content: "\e94e";
}
.entypo-trash:before {
  content: "\e94f";
}
.entypo-triangle-down:before {
  content: "\e950";
}
.entypo-triangle-left:before {
  content: "\e951";
}
.entypo-triangle-right:before {
  content: "\e952";
}
.entypo-triangle-up:before {
  content: "\e953";
}
.entypo-uninstall:before {
  content: "\e954";
}
.entypo-upload-to-cloud:before {
  content: "\e955";
}
.entypo-upload:before {
  content: "\e956";
}
.entypo-add-user:before {
  content: "\e957";
}
.entypo-address:before {
  content: "\e958";
}
.entypo-adjust:before {
  content: "\e959";
}
.entypo-air:before {
  content: "\e95a";
}
.entypo-aircraft-landing:before {
  content: "\e95b";
}
.entypo-aircraft-take-off:before {
  content: "\e95c";
}
.entypo-aircraft:before {
  content: "\e95d";
}
.entypo-align-bottom:before {
  content: "\e95e";
}
.entypo-align-horizontal-middle:before {
  content: "\e95f";
}
.entypo-align-left:before {
  content: "\e960";
}
.entypo-align-right:before {
  content: "\e961";
}
.entypo-align-top:before {
  content: "\e962";
}
.entypo-align-vertical-middle:before {
  content: "\e963";
}
.entypo-archive:before {
  content: "\e964";
}
.entypo-area-graph:before {
  content: "\e965";
}
.entypo-attachment:before {
  content: "\e966";
}
.entypo-awareness-ribbon:before {
  content: "\e967";
}
.entypo-back-in-time:before {
  content: "\e968";
}
.entypo-back:before {
  content: "\e969";
}
.entypo-bar-graph:before {
  content: "\e96a";
}
.entypo-battery:before {
  content: "\e96b";
}
.entypo-beamed-note:before {
  content: "\e96c";
}
.entypo-bell:before {
  content: "\e96d";
}
.entypo-blackboard:before {
  content: "\e96e";
}
.entypo-block:before {
  content: "\e96f";
}
.entypo-book:before {
  content: "\e970";
}
.entypo-bowl:before {
  content: "\e971";
}
.entypo-box:before {
  content: "\e972";
}
.entypo-briefcase:before {
  content: "\e973";
}
.entypo-browser:before {
  content: "\e974";
}
.entypo-brush:before {
  content: "\e975";
}
.entypo-bucket:before {
  content: "\e976";
}
.entypo-cake:before {
  content: "\e977";
}
.entypo-calculator:before {
  content: "\e978";
}
.entypo-calendar:before {
  content: "\e979";
}
.entypo-camera:before {
  content: "\e97a";
}
.entypo-ccw:before {
  content: "\e97b";
}
.entypo-chat:before {
  content: "\e97c";
}
.entypo-check:before {
  content: "\e97d";
}
.entypo-circle-with-cross:before {
  content: "\e97e";
}
.entypo-circle-with-minus:before {
  content: "\e97f";
}
.entypo-circle-with-plus:before {
  content: "\e980";
}
.entypo-circle:before {
  content: "\e981";
}
.entypo-circular-graph:before {
  content: "\e982";
}
.entypo-clapperboard:before {
  content: "\e983";
}
.entypo-clipboard:before {
  content: "\e984";
}
.entypo-clock:before {
  content: "\e985";
}
.entypo-code:before {
  content: "\e986";
}
.entypo-cog:before {
  content: "\e987";
}
.entypo-colours:before {
  content: "\e988";
}
.entypo-compass:before {
  content: "\e989";
}
.entypo-copy:before {
  content: "\e98a";
}
.entypo-credit-card:before {
  content: "\e98b";
}
.entypo-credit:before {
  content: "\e98c";
}
.entypo-cross:before {
  content: "\e98d";
}
.entypo-cup:before {
  content: "\e98e";
}
.entypo-cw:before {
  content: "\e98f";
}
.entypo-cycle:before {
  content: "\e990";
}
.entypo-database:before {
  content: "\e991";
}
.entypo-dial-pad:before {
  content: "\e992";
}
.entypo-direction:before {
  content: "\e993";
}
.entypo-document:before {
  content: "\e994";
}
.entypo-documents:before {
  content: "\e995";
}
.entypo-drink:before {
  content: "\e996";
}
.entypo-drive:before {
  content: "\e997";
}
.entypo-drop:before {
  content: "\e998";
}
.entypo-edit:before {
  content: "\e999";
}
.entypo-email:before {
  content: "\e99a";
}
.entypo-emoji-happy:before {
  content: "\e99b";
}
.entypo-emoji-neutral:before {
  content: "\e99c";
}
.entypo-emoji-sad:before {
  content: "\e99d";
}
.entypo-erase:before {
  content: "\e99e";
}
.entypo-eraser:before {
  content: "\e99f";
}
.entypo-export:before {
  content: "\e9a0";
}
.entypo-eye:before {
  content: "\e9a1";
}
.entypo-feather:before {
  content: "\e9a2";
}
.entypo-flag:before {
  content: "\e9a3";
}
.entypo-flash:before {
  content: "\e9a4";
}
.entypo-flashlight:before {
  content: "\e9a5";
}
.entypo-flat-brush:before {
  content: "\e9a6";
}
.entypo-folder-images:before {
  content: "\e9a7";
}
.entypo-folder-music:before {
  content: "\e9a8";
}
.entypo-folder-video:before {
  content: "\e9a9";
}
.entypo-folder:before {
  content: "\e9aa";
}
.entypo-forward:before {
  content: "\e9ab";
}
.entypo-funnel:before {
  content: "\e9ac";
}
.entypo-game-controller:before {
  content: "\e9ad";
}
.entypo-gauge:before {
  content: "\e9ae";
}
.entypo-globe:before {
  content: "\e9af";
}
.entypo-graduation-cap:before {
  content: "\e9b0";
}
.entypo-grid:before {
  content: "\e9b1";
}
.entypo-hair-cross:before {
  content: "\e9b2";
}
.entypo-hand:before {
  content: "\e9b3";
}
.entypo-heart-outlined:before {
  content: "\e9b4";
}
.entypo-heart:before {
  content: "\e9b5";
}
.entypo-help-with-circle:before {
  content: "\e9b6";
}
.entypo-help:before {
  content: "\e9b7";
}
.entypo-home:before {
  content: "\e9b8";
}
.entypo-hour-glass:before {
  content: "\e9b9";
}
.entypo-image-inverted:before {
  content: "\e9ba";
}
.entypo-image:before {
  content: "\e9bb";
}
.entypo-images:before {
  content: "\e9bc";
}
.entypo-inbox:before {
  content: "\e9bd";
}
.entypo-infinity:before {
  content: "\e9be";
}
.entypo-info-with-circle:before {
  content: "\e9bf";
}
.entypo-info:before {
  content: "\e9c0";
}
.entypo-key:before {
  content: "\e9c1";
}
.entypo-keyboard:before {
  content: "\e9c2";
}
.entypo-lab-flask:before {
  content: "\e9c3";
}
.entypo-landline:before {
  content: "\e9c4";
}
.entypo-language:before {
  content: "\e9c5";
}
.entypo-laptop:before {
  content: "\e9c6";
}
.entypo-leaf:before {
  content: "\e9c7";
}
.entypo-level-down:before {
  content: "\e9c8";
}
.entypo-level-up:before {
  content: "\e9c9";
}
.entypo-lifebuoy:before {
  content: "\e9ca";
}
.entypo-light-bulb:before {
  content: "\e9cb";
}
.entypo-light-down:before {
  content: "\e9cc";
}
.entypo-light-up:before {
  content: "\e9cd";
}
.entypo-line-graph:before {
  content: "\e9ce";
}
.entypo-link:before {
  content: "\e9cf";
}
.entypo-list:before {
  content: "\e9d0";
}
.entypo-location-pin:before {
  content: "\e9d1";
}
.entypo-location:before {
  content: "\e9d2";
}
.entypo-lock-open:before {
  content: "\e9d3";
}
.entypo-lock:before {
  content: "\e9d4";
}
.entypo-log-out:before {
  content: "\e9d5";
}
.entypo-login:before {
  content: "\e9d6";
}
.entypo-loop:before {
  content: "\e9d7";
}
.entypo-magnet:before {
  content: "\e9d8";
}
.entypo-magnifying-glass:before {
  content: "\e9d9";
}
.entypo-mail:before {
  content: "\e9da";
}
.entypo-man:before {
  content: "\e9db";
}
.entypo-map:before {
  content: "\e9dc";
}
.entypo-mask:before {
  content: "\e9dd";
}
.entypo-medal:before {
  content: "\e9de";
}
.entypo-megaphone:before {
  content: "\e9df";
}
.entypo-menu:before {
  content: "\e9e0";
}
.entypo-message:before {
  content: "\e9e1";
}
.entypo-mic:before {
  content: "\e9e2";
}
.entypo-minus:before {
  content: "\e9e3";
}
.entypo-mobile:before {
  content: "\e9e4";
}
.entypo-modern-mic:before {
  content: "\e9e5";
}
.entypo-moon:before {
  content: "\e9e6";
}
.entypo-mouse:before {
  content: "\e9e7";
}
.entypo-music:before {
  content: "\e9e8";
}
.entypo-network:before {
  content: "\e9e9";
}
.entypo-new-message:before {
  content: "\e9ea";
}
.entypo-new:before {
  content: "\e9eb";
}
.entypo-news:before {
  content: "\e9ec";
}
.entypo-note:before {
  content: "\e9ed";
}
.entypo-notification:before {
  content: "\e9ee";
}
.entypo-old-mobile:before {
  content: "\e9ef";
}
.entypo-old-phone:before {
  content: "\e9f0";
}
.entypo-palette:before {
  content: "\e9f1";
}
.entypo-paper-plane:before {
  content: "\e9f2";
}
.entypo-pencil:before {
  content: "\e9f3";
}
.entypo-phone:before {
  content: "\e9f4";
}
.entypo-pie-chart:before {
  content: "\e9f5";
}
.entypo-pin:before {
  content: "\e9f6";
}
.entypo-plus:before {
  content: "\e9f7";
}
.entypo-popup:before {
  content: "\e9f8";
}
.entypo-power-plug:before {
  content: "\e9f9";
}
.entypo-price-ribbon:before {
  content: "\e9fa";
}
.entypo-price-tag:before {
  content: "\e9fb";
}
.entypo-print:before {
  content: "\e9fc";
}
.entypo-progress-empty:before {
  content: "\e9fd";
}
.entypo-progress-full:before {
  content: "\e9fe";
}
.entypo-progress-one:before {
  content: "\e9ff";
}
.entypo-progress-two:before {
  content: "\ea00";
}
.entypo-publish:before {
  content: "\ea01";
}
.entypo-quote:before {
  content: "\ea02";
}
.entypo-radio:before {
  content: "\ea03";
}
.entypo-reply-all:before {
  content: "\ea04";
}
.entypo-reply:before {
  content: "\ea05";
}
.entypo-retweet:before {
  content: "\ea06";
}
.entypo-rocket:before {
  content: "\ea07";
}
.entypo-round-brush:before {
  content: "\ea08";
}
.entypo-rss:before {
  content: "\ea09";
}
.entypo-ruler:before {
  content: "\ea0a";
}
.entypo-scissors:before {
  content: "\ea0b";
}
.entypo-share-alternitive:before {
  content: "\ea0c";
}
.entypo-share:before {
  content: "\ea0d";
}
.entypo-shareable:before {
  content: "\ea0e";
}
.entypo-shield:before {
  content: "\ea0f";
}
.entypo-shop:before {
  content: "\ea10";
}
.entypo-shopping-bag:before {
  content: "\ea11";
}
.entypo-shopping-basket:before {
  content: "\ea12";
}
.entypo-shopping-cart:before {
  content: "\ea13";
}
.entypo-shuffle:before {
  content: "\ea14";
}
.entypo-signal:before {
  content: "\ea15";
}
.entypo-sound-mix:before {
  content: "\ea16";
}
.entypo-sports-club:before {
  content: "\ea17";
}
.entypo-spreadsheet:before {
  content: "\ea18";
}
.entypo-squared-cross:before {
  content: "\ea19";
}
.entypo-squared-minus:before {
  content: "\ea1a";
}
.entypo-squared-plus:before {
  content: "\ea1b";
}
.entypo-star-outlined:before {
  content: "\ea1c";
}
.entypo-star:before {
  content: "\ea1d";
}
.entypo-stopwatch:before {
  content: "\ea1e";
}
.entypo-suitcase:before {
  content: "\ea1f";
}
.entypo-swap:before {
  content: "\ea20";
}
.entypo-sweden:before {
  content: "\ea21";
}
.entypo-switch:before {
  content: "\ea22";
}
.entypo-tablet:before {
  content: "\ea23";
}
.entypo-tag:before {
  content: "\ea24";
}
.entypo-text-document-inverted:before {
  content: "\ea25";
}
.entypo-text-document:before {
  content: "\ea26";
}
.entypo-text:before {
  content: "\ea27";
}
.entypo-thermometer:before {
  content: "\ea28";
}
.entypo-thumbs-down:before {
  content: "\ea29";
}
.entypo-thumbs-up:before {
  content: "\ea2a";
}
.entypo-thunder-cloud:before {
  content: "\ea2b";
}
.entypo-ticket:before {
  content: "\ea2c";
}
.entypo-time-slot:before {
  content: "\ea2d";
}
.entypo-tools:before {
  content: "\ea2e";
}
.entypo-traffic-cone:before {
  content: "\ea2f";
}
.entypo-tree:before {
  content: "\ea30";
}
.entypo-trophy:before {
  content: "\ea31";
}
.entypo-tv:before {
  content: "\ea32";
}
.entypo-typing:before {
  content: "\ea33";
}
.entypo-unread:before {
  content: "\ea34";
}
.entypo-untag:before {
  content: "\ea35";
}
.entypo-user:before {
  content: "\ea36";
}
.entypo-users:before {
  content: "\ea37";
}
.entypo-v-card:before {
  content: "\ea38";
}
.entypo-video:before {
  content: "\ea39";
}
.entypo-vinyl:before {
  content: "\ea3a";
}
.entypo-voicemail:before {
  content: "\ea3b";
}
.entypo-wallet:before {
  content: "\ea3c";
}
.entypo-water:before {
  content: "\ea3d";
}
.entypo-500px-with-circle:before {
  content: "\ea3e";
}
.entypo-500px:before {
  content: "\ea3f";
}
.entypo-basecamp:before {
  content: "\ea40";
}
.entypo-behance:before {
  content: "\ea41";
}
.entypo-creative-cloud:before {
  content: "\ea42";
}
.entypo-dropbox:before {
  content: "\ea43";
}
.entypo-evernote:before {
  content: "\ea44";
}
.entypo-flattr:before {
  content: "\ea45";
}
.entypo-foursquare:before {
  content: "\ea46";
}
.entypo-google-drive:before {
  content: "\ea47";
}
.entypo-google-hangouts:before {
  content: "\ea48";
}
.entypo-grooveshark:before {
  content: "\ea49";
}
.entypo-icloud:before {
  content: "\ea4a";
}
.entypo-mixi:before {
  content: "\ea4b";
}
.entypo-onedrive:before {
  content: "\ea4c";
}
.entypo-paypal:before {
  content: "\ea4d";
}
.entypo-picasa:before {
  content: "\ea4e";
}
.entypo-qq:before {
  content: "\ea4f";
}
.entypo-rdio-with-circle:before {
  content: "\ea50";
}
.entypo-renren:before {
  content: "\ea51";
}
.entypo-scribd:before {
  content: "\ea52";
}
.entypo-sina-weibo:before {
  content: "\ea53";
}
.entypo-skype-with-circle:before {
  content: "\ea54";
}
.entypo-skype:before {
  content: "\ea55";
}
.entypo-slideshare:before {
  content: "\ea56";
}
.entypo-smashing:before {
  content: "\ea57";
}
.entypo-soundcloud:before {
  content: "\ea58";
}
.entypo-spotify-with-circle:before {
  content: "\ea59";
}
.entypo-spotify:before {
  content: "\ea5a";
}
.entypo-swarm:before {
  content: "\ea5b";
}
.entypo-vine-with-circle:before {
  content: "\ea5c";
}
.entypo-vine:before {
  content: "\ea5d";
}
.entypo-vk-alternitive:before {
  content: "\ea5e";
}
.entypo-vk-with-circle:before {
  content: "\ea5f";
}
.entypo-vk:before {
  content: "\ea60";
}
.entypo-xing-with-circle:before {
  content: "\ea61";
}
.entypo-xing:before {
  content: "\ea62";
}
.entypo-yelp:before {
  content: "\ea63";
}
.entypo-dribbble-with-circle:before {
  content: "\ea64";
}
.entypo-dribbble:before {
  content: "\ea65";
}
.entypo-facebook-with-circle:before {
  content: "\ea66";
}
.entypo-facebook:before {
  content: "\ea67";
}
.entypo-flickr-with-circle:before {
  content: "\ea68";
}
.entypo-flickr:before {
  content: "\ea69";
}
.entypo-github-with-circle:before {
  content: "\ea6a";
}
.entypo-github:before {
  content: "\ea6b";
}
.entypo-google-with-circle:before {
  content: "\ea6c";
}
.entypo-google:before {
  content: "\ea6d";
}
.entypo-instagram-with-circle:before {
  content: "\ea6e";
}
.entypo-instagram:before {
  content: "\ea6f";
}
.entypo-lastfm-with-circle:before {
  content: "\ea70";
}
.entypo-lastfm:before {
  content: "\ea71";
}
.entypo-linkedin-with-circle:before {
  content: "\ea72";
}
.entypo-linkedin:before {
  content: "\ea73";
}
.entypo-pinterest-with-circle:before {
  content: "\ea74";
}
.entypo-pinterest:before {
  content: "\ea75";
}
.entypo-rdio:before {
  content: "\ea76";
}
.entypo-stumbleupon-with-circle:before {
  content: "\ea77";
}
.entypo-stumbleupon:before {
  content: "\ea78";
}
.entypo-tumblr-with-circle:before {
  content: "\ea79";
}
.entypo-tumblr:before {
  content: "\ea7a";
}
.entypo-twitter-with-circle:before {
  content: "\ea7b";
}
.entypo-twitter:before {
  content: "\ea7c";
}
.entypo-vimeo-with-circle:before {
  content: "\ea7d";
}
.entypo-vimeo:before {
  content: "\ea7e";
}
.entypo-youtube-with-circle:before {
  content: "\ea7f";
}
.entypo-youtube:before {
  content: "\ea80";
}

