#project-hero {
  height: 550px;
  position: relative;
  overflow: hidden;
  background: #909396 url('#{$imagesPath}/loading-dark.gif') no-repeat center center;
  background-position: center center;

  @media(max-width: 767px) {
    height: 500px;
  }

  &.show {
    .inner-bg {
      opacity: 1;
    }
  }

  .inner-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-size: cover;
    background-position: center center;
    background-image: url('#{$imagesPath}/ecommerceb2b/banner.jpg');    

    @include transition(opacity 0.5s);

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(32, 43, 55, 0.4) url('#{$imagesPath}/graphics/noise.png');
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    img {
      width: 0;
      height: 0;
      visibility: hidden;
      position: absolute;
    }
  }

  .inner-bg-b2c {
    background-image: url('#{$imagesPath}/ecommerceb2c/banner.jpg');
  }

  > section {
    position: relative;
    z-index: 999;

    @include transition(.8s);
    @include transform(scale(1.13));

    &.ready {
      @include transform(scale(1));
    }
  }

  h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    position: relative;
    padding-top: 240px;
    margin: 0;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.14);
    letter-spacing: .3px;

    @media(max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
      padding-top: 200px;
    }
  }
}

.project-b2c-features {
  background: #fafafa;
  border-top: 1px solid #e2e1e1;
  border-bottom: 1px solid #e2e1e1;
  padding: 80px 0;
  position: relative;

  header {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -50px;
      height: 1px;
      width: 87%;
      left: 0;
      right: 0;
      margin: auto;
      background: #eaeaea;
    }

    h3 {
      @media(max-width: 767px) {
        font-size: 23px;
      }
    }

    p {
      color: #666;
      font-size: 15px;
      text-align: center;
      line-height: 25px;
      width: 75%;
      margin: 0 auto;
      margin-top: 30px;
    }
  }

  .features {
    margin: auto;
    margin-top: 110px;
    width: 88%;

    ul {
      li {
        margin-bottom: 20px;
      }
    }

    i {
      color: #1D2951;
    }

    .icon {
      @media(min-width: 992px) {
        margin-right: 20px;
      }
    }

    .feature {
      margin-bottom: 70px;

      @media(max-width: 767px) {
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
      }
    }

    section {
      width: 83%;
      float: right;

      @media(max-width: 991px) {
        width: 100%;
        margin-top: 20px;
        float: none;
      }

      h4 {
        margin-top: 0;
        font-size: 17px;
        color: #1d2951;
      }

      p {
        margin-top: 18px;
        font-size: 15px;
        line-height: 24px;
        color: #666;
        width: 95%;
      }
    }
  }
}

#project-intro {
  margin-top: 90px;
  margin-bottom: 80px;
  position: relative;
  z-index: 10;

  p {
    width: 60%;
    margin: auto;
    text-align: justify;
    margin-bottom: 19px;

    @media(max-width: 991px) {
      width: 80%;
    }
  }

  .devices {
    margin-top: 90px;
  }
}

#project-showcase {
  background: #F5F8FA;
  padding: 50px 0 80px;
  position: relative;
  border-top: 1px solid #E2E6E8;
  border-bottom: 1px solid #E2E6E8;

  h3 {
    margin-bottom: 40px;
    margin-top: 30px;
  }

  p {
    width: 60%;
    text-align: justify;
    margin: auto;
    margin-bottom: 25px;

    @media(max-width: 991px) {
      width: 80%;
    }
  }

  .browser {
    margin-top: 80px;
    margin-bottom: 100px;
  }

  .browser2 {
    margin-top: 80px;
  }

  .project-etapas {

    h4 {
      font-size: 21px;
      font-weight: 400;
      color: #1D2951;
    }

    .card {

      margin-bottom: 10px;

    }

    .card-text {

      font-size: 14px;
      text-align: left;
      width: 100%;

    }

  }

}

#project-demonstrativo {
  padding-top: 80px;
  p {
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    font-size: 21px;
  }

  form {
    width: 60%;
    margin: auto;

    .btn-primary {
      background-color: #1d2951;
      border-color: #1d2951;
    }

    @media(max-width: 991px) {
      width: 80%;
    }

  }

}

#project-bottom {
  margin-top: 30px;
  
  .link-to-site {
    text-align: center;

    a {
      color: #5b8ec1;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500;
      text-decoration: none;
      position: relative;

      @include transition(all .2s linear);

      &:after {
        content: '';
        position: absolute;
        height: 2px;
        background: #5b8ec1;
        width: 0%;
        bottom: -7px;
        left: 0;

        @include transition(all .15s linear);
      }

      &:hover {
        text-decoration: none;

        &:after {
          width: 100%;
        }
      }
    }
  }

  .paging {
    padding: 30px 100px;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;

    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: -100px;

    @media(max-width: 767px) {
      width: auto;
      margin-bottom: -50px;
    }

    .previous,
    .next {
      @media(max-width: 767px) {
        float: none !important;
        text-align: center;
      }
    }

    a {
      font-family: $merriweather;
      font-style: italic;
      font-size: 15px;
      color: #555;
      text-decoration: none;

      @include transition(all .2s linear);

      @media(max-width: 767px) {
        margin-top: 10px;
        display: block;
      }

      &:hover {
        color: #000;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  #project-intro{
    margin: 50px 0;
  } 
  #project-showcase {
    padding: 50px 0 40px;
  }
  #project-demonstrativo {
    padding-top: 50px;
  }
  .project-b2c-features {
    padding: 50px 0;
  }
}
@media screen and (max-width: 425px) {
  #project-intro {
    margin: 30px 0;
  }
  #project-showcase {
    padding: 30px 0 20px;
  }
  #project-demonstrativo {
    padding-top: 30px;
  }
  .project-b2c-features {
    padding: 30px 0; 
    header:after {
      bottom: -25px;
    }
    .features {
        margin-top: 60px;
    }
  }

}