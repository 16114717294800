.blog-cols-page {
  background: #FCFCFC;
}

.blog-cols-header {
  margin-top: 60px;
  position: relative;

  h1 {
    font-size: 23px;
    margin-top: 0;
    color: #555;
  }

  p {
    color: #757575;
    font-size: 17px;
    margin-top: 12px;
  }
}

.blog-cols-wrapper {
  margin-top: 40px;

  @media(max-width: 767px) {
    text-align: center;
  }

  .post {
    display: inline-block;

    @media(min-width: 992px) {
      &:nth-child(3n+3) {
        .post-wrapper {
          margin: 20px 0;
        }
      }
    }

    @media(min-width: 768px) and (max-width: 991px) {
      &:nth-child(2n+2) {
        .post-wrapper {
          margin: 20px 0;
        }
      }
    }

    .post-wrapper {
      display: inline-block;
      position: relative;
      width: 330px;
      background-color: #fff;
      vertical-align: top;
      text-align: left;
      color: #4B4F56;
      height: 480px;
      margin: 20px 20px 30px 0;
      box-shadow: 0 20px 20px rgba(0,0,0,.08);

      @include transition(all 250ms cubic-bezier(.02, .01, .47, 1));

      @media(max-width: 1199px) {
        width: 293px;
      }

      @media(max-width: 991px) {
        width: 330px;
      }

      @media(max-width: 767px) {
        margin: 20px 0;
      }

      &:hover {
        box-shadow: 0 40px 40px rgba(0,0,0,.16);
        transform: translate(0,-20px);
      }
    }

    .post-bg {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      height: 295px;

      @media(max-width: 1199px) {
        height: 260px;
      }
    }

    .post-intro {
      padding: 20px 20px 0;
    }

    .post-title {
      font-size: 22px;
      line-height: 32px;
    }

    .post-min-read {
      margin-top: 5px;
      font-size: 12px;
      color: #777;
    }

    .post-description {
      font-size: 15px;
      margin-top: 13px;
      line-height: 23px;
      color: #90949C;
    }
  }
}
