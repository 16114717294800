.btn-group {
  .btn-primary {
    border-color: #075698;
  }

  .btn-success {
    border-color: #4d9a4d;
  }

  .btn-info {
    border-color: #54a3bb;
  }

  .btn-warning {
    border-color: #ce9648;
  }

  .btn-danger {
    border-color: #af4744;
  }
}
