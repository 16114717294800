.agency-about-hero {
  height: 550px;
  position: relative;
  background-image: url('#{$imagesPath}/quemsomos/banner.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;

  @media(max-width: 1024px) {
    background-attachment: inherit;
  }

  @media(max-width: 768px) {
    height: 480px;
  }

  @media(max-width: 425px) {
    height: 270px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(bottom left, rgba(28, 46, 79, 0.62) 0%, rgba(28, 46, 79, 0.7) 14.87%, rgba(45, 83, 123, 0.6) 100%);
    background: linear-gradient(to bottom left, rgba(28, 46, 79, 0.62) 0%, rgba(28, 46, 79, 0.7) 14.87%, rgba(45, 83, 123, 0.6) 100%);
  }

  section {
    position: relative;
    z-index: 9;
    height: 100%;
  }

  .hero-text {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -60px;

    p {
      color: #fff;
      text-align: center;
      width: 70%;
      font-size: 36px;
      font-weight: 400;
      position: relative;
      line-height: 40px;
      text-shadow: 1px 1px rgba(0,0,0,.14);
      letter-spacing: .3px;
    }

    @media(max-width: 767px) {
      top: 170px;
    }
  }

  p {
    color: #fff;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    width: 70%;
    margin: 0 auto;

    @include animationDelay(.3s);

    @media(max-width: 767px) {
      font-size: 21px;
      width: 80%;
    }
  }
}
.bg-cases {
  background-image: url('#{$imagesPath}/cases/banner.jpg');
}
.bg-b2c {
  background-image: url('#{$imagesPath}/ecommerceb2c/banner.jpg');
}
.bg-b2b {
  background-image: url('#{$imagesPath}/unsplash/photo-1474843148229-3163319fcc4c.jpg');
}

.agency-about-intro {
  margin-top: 90px;

  h3 {
    text-align: left;
    margin-bottom: 1rem;
  }

  p {
    text-align: left;

    @media(max-width: 767px) {
      width: auto;
    }
  }
} 

.agency-about-team {
  margin-top: 100px;
  background: #F9F9F9;
  padding-top: 50px;
  padding-bottom: 70px;
  border-top: 1px solid #F0F0F0;

  + footer {
    margin-top: 0;
  }

  h4 {
    text-align: center;
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  h3 {
    margin-bottom: 30px;
  }

  hr {
    width: 50px;
    margin: 20px auto 25px;
    border-top: 2px solid #ccc;
  }

  .member {
    text-align: center;
    margin: 0 auto;
    width: 128px;
    height: 128px;
    position: relative;

    &:hover {
      .mask {
        opacity: 1;
      }
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      background: rgba(29, 32, 34, 0.7);
      opacity: 0;

      @include transition(all .4s);

      a {
        font-size: 18px;
        text-align: center;
        margin: 0 3px;
        position: relative;
        top: 52px;
        width: 25px;
        height: 25px;
        display: inline-block;
        background: #fff;
        border-radius: 50%;
        color: rgba(56, 64, 74, 1);
      }
    }

    img {
      border-radius: 50%;
    }
  }

  .name {
    text-align: center;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    margin-bottom: 45px;
    font-weight: 500;
  }
}


@media screen and (max-width: 768px) {
  .agency-about-hero {
    .hero-text {
      margin-top: -80px;
      p {
        font-size: 36px;
      }      
    }
  } 
  .agency-about-intro, .agency-about-team {
    margin-top: 50px;
  } 
}
@media screen and (max-width: 425px) {
  .agency-about-hero {
    .hero-text {
      top: 15%;    
      margin-top: 0;
      p {
        font-size: 26px;
        line-height: 28px;
      }
    }
  }
  .agency-about-intro, .agency-about-team {
    margin-top: 30px;
  }
}

@include keyFrame(collage) {
  0% {
    @include transform(translate3d(0, 0, 0));
  }
  100% {
    @include transform(translate3d(-1810px, 0, 0));
  }
}

.agency-about-pics {
  position: relative;
  height: 450px;
  width: 100%;
  max-width: inherit;
  overflow: hidden;

  #photos {
    position: absolute;
    height: 100%;
    width: 5430px;
    background: transparent url('#{$imagesPath}/graphics/collage.jpg') repeat-x left top;
    
    @include transform(translate3d(0, 0, 0));
    @include animation(collage 60s linear infinite);
  }
}

.agency-about-cta {
  margin-top: 70px;
  text-align: center;

  h3 {
    font-size: 28px;
    color: #4a5969;

    @media(max-width: 767px) {
      font-size: 25px;
    }
  }

  p {
    font-size: 16px;
    color: #7B899E;
    margin-top: 18px;
    line-height: 28px;
  }
}

.agency-about-clients {
  margin-top: 140px;
  margin-bottom: -60px;
  text-align: center;

  header {
    margin-bottom: 30px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: #E9E9E9;
      top: 11px;
    }

    h4 {
      position: relative;
      padding: 0 40px;
      background: #fff;
      color: #97A8B8;
      font-family: $lato;
      display: inline-block;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 1.8px;
    }
  }
}
