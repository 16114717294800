.agency-contact-hero {
  min-height: 290px;
  position: relative;
  background-image: url('#{$imagesPath}/graphics/hero-02.jpg');
  background-size: cover;
  background-position: center -40px;

  @media(max-width: 767px) {
    background-position: center center;
    min-height: 270px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  h3 {
    position: relative;
    top: 200px;
    color: #fff;
    text-align: center;
    z-index: 1;
    font-size: 29px;
    font-weight: 400;
    line-height: 43px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.3);

    @media(max-width: 767px) {
      font-size: 25px;
      top: 130px;
    }
  }
}

.agency-contact-intro {
  margin-top: 90px;

  p {
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
    text-align: center;
    width: 75%;
    margin: 27px auto 0;
    color: #61686E;

    @media(max-width: 767px) {
      width: auto;
      font-size: 15px;
      line-height: 27px;
    }
  }
}

.agency-contact-form {

  

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 40px;
    margin-left: 15px;
    margin-bottom: 25px;
    color: #1D2951;
  }

  .informacoes {

    h3 {
      margin-bottom: 5px;
      margin-top: 20px;

      &:first-child {
        margin-top: 40px;
      }

    }

    p {
      margin-left: 15px;
      i.fa-whatsapp {
        font-size: 16px; 
      }
    }

  }

  form {

    @media(max-width: 767px) {
      width: 100%;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      outline: none;
      color: #888;
      background: white;
      border: 1px solid #cbcdd2;
      width: 100%;
      border-radius: 4px;

      @include transition(all .2s);

      @include placeholder {
        color: #B8BBC7;
      }

      @media(max-width: 767px) {
        padding: 15px 20px;
        font-size: 16px;
      }

      &:focus {
        border-color: #656565;

        @include placeholder {
          color: #666;
        }
      }
    }

    input[type="submit"] {
      background: linear-gradient(to bottom, #6fb1f5 0%, #3b97f7 100%);
      box-shadow: rgba(23, 43, 99, 0.24) 0 7px 28px;
      width: 100%;
      padding: 18px 20px;
      text-align: center;
      outline: none;
      border: none;
      cursor: pointer;
      margin-top: 23px;
      font-weight: 500;
      border-radius: 4px;
      font-size: 18px;
      color: #fff;
      text-shadow: 1px 1px 0px rgba(106, 132, 158, 0.6);

      @include transition(all .2s);

      &:hover {
        color: #fff;
      }
    }
  }
}

.agency-contact-map {
  margin-top: 120px;
  position: relative;
  height: 450px;
  width: 100%;

  #map {
    height: 450px;
    width: 100%;
  }

  + footer {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .agency-contact-intro{
    margin-top: 50px;
  } 
}
@media screen and (max-width: 425px) {
  .agency-contact-intro {
    margin-top: 30px;
  }
}